import React, { useContext, useEffect } from 'react';
import GlobalContext from '../../../../../../state/GlobalContext';
import { PaymentStatus } from '../../../../../../types';
import { postSale } from '../../../../../../services/payment.service';
import { useRequestContext } from '../../../../../../hooks';
import * as S from './StripePayment.styles';
import {
  getStripeCheckoutStatus,
  postStripeCheckout
} from '../../../../../../services/stripePayment.service';
import { useTranslation } from 'react-i18next';
import { DisableScreenClick } from '../../Payment.styles';
import { Loader } from '@k3imagine/self-serve-components';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../../../../../../utils/namedLocalStorage';

type PaymentProps = {
  goNext: Function;
  table: any;
};

const StripePayment = ({ goNext, table }: PaymentProps) => {
  const { currencyCode, basket, customerInfo } = useContext(GlobalContext);
  const { t } = useTranslation();
  const requestContext = useRequestContext();

  useEffect(() => {
    const stripeSessionId = getNamedLocalStorage('stripeSessionId');

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('stripeSuccess') || queryParams.has('stripeFailed')) {
      stripeProcessPaymentResponse();
    } else {
      if (stripeSessionId == '' || stripeSessionId == null) {
        stripeProcessTransaction();
      }
    }
  }, []);

  const stripeProcessTransaction = async () => {
    const encryptedTableNumber = createTableUrl();

    // Create basket object to send to GW
    const stripeBasket = {
      externalUid: basket.externalUid,
      currency: currencyCode,
      basketLines: basket.basketItems.map(item => ({
        name: item.label,
        price: item.price,
        quantity: item.quantity
      }))
    };

    // Post basket to stripe MS via gateway
    await postStripeCheckout(stripeBasket, encryptedTableNumber)
      .then(stripeResponse => {
        // Save the sessionId to local storage
        let sessionId = stripeResponse.data.sessionId
          ? stripeResponse.data.sessionId
          : '';
        setNamedLocalStorage('stripeSessionId', sessionId);

        if (stripeResponse.data.stripePageUrl) {
          //Navigate to stripe URL to process payment
          window.location.href = stripeResponse.data.stripePageUrl;
        }
      })
      .catch(e => {
        console.error(e);
        goNext({ paymentStatus: PaymentStatus.Failure });
      });
  };

  const stripeProcessPaymentResponse = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has('stripeFailed')) {
        deleteUrlParam('stripeFailed');
        setNamedLocalStorage('stripeSessionId', '');
        goNext({ paymentStatus: PaymentStatus.Failure });
      } else {
        deleteUrlParam('stripeSuccess');
        // Retrieve the sessionId from local storage
        const stripeSessionId = getNamedLocalStorage('stripeSessionId');

        // Check if processed ok (loop # times)
        if (stripeSessionId) {
          let checkoutRequestCount = 0;
          let stayInLoop = true;
          let status = '';

          do {
            status = await getCheckoutStatus(stripeSessionId);

            if (status != 'Processing') {
              stayInLoop = false;
            }

            checkoutRequestCount++;
          } while (stayInLoop && checkoutRequestCount < 60);

          if (status != 'Completed') {
            setNamedLocalStorage('stripeSessionId', '');
            goNext({ paymentStatus: PaymentStatus.Failure });
          } else {
            const response = await postSale(
              customerInfo.email,
              basket.basketItems,
              basket.externalUid
            );

            goNext({
              paymentStatus: PaymentStatus.Success,
              salesResponse: response.data
            });
          }
        } else {
          console.error('No stripeSessionId to process');
          goNext({ paymentStatus: PaymentStatus.Failure });
        }
      }
    } catch (error) {
      setNamedLocalStorage('stripeSessionId', '');
      goNext({ paymentStatus: PaymentStatus.Failure });
    }
  };

  const getCheckoutStatus = async (
    stripeSessionId: string
  ): Promise<string> => {
    const result = await getStripeCheckoutStatus(stripeSessionId);

    let status = result.data.status ? result.data.status : '';
    await delay(1000);

    return status;
  };

  const deleteUrlParam = (param: string) => {
    // Here we just remove the redirected part of the url.
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete(param);

    // Get the updated URL without the parameter
    const updatedUrl = `${window.location.origin}${
      window.location.pathname
    }?${queryParams.toString()}`;

    // Use history.pushState to update the URL without triggering a page reload
    window.history.pushState({ path: updatedUrl }, '', updatedUrl);
  };

  const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const createTableUrl = () => {
    /**
     * The logic behind the generated codes
     * tableNumber    shopId    tenantId
     * 0001           0001      00001     =>    0001000100001 => 1000100001 => to base36 => GJFM3L
     */
    const { tenantId } = requestContext;
    const paddedTenantId = tenantId?.padStart(5, '0');
    const paddedShopId = requestContext.shopId?.toString().padStart(4, '0');
    const encodedUrl = Number(`${table.id}${paddedShopId}${paddedTenantId}`)
      .toString(36)
      .toUpperCase();

    return encodedUrl;
  };

  return (
    <>
      <DisableScreenClick />
      <h1>
        <S.Title>{t('Basket.PaymentInProgress')}</S.Title>
      </h1>
      <Loader width={200} color="black" />
    </>
  );
};

export default StripePayment;
