import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  TransactionStatus,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import { PaymentStatus as PaymentStatusType } from '../../../../types/index';
import GlobalContext from '../../../../state/GlobalContext';

type PaymentStatusProps = {
  status: PaymentStatusType | null;
  orderId?: string | number;
  averageWaitingTimeInSeconds?: number;
  onGoToBasketOverview: Function;
  onGoToStart: (hide?: boolean) => PromiseLike<void>;
};

const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
`;

const PaymentStatus = ({
  status,
  onGoToBasketOverview,
  orderId,
  averageWaitingTimeInSeconds,
  onGoToStart
}: PaymentStatusProps) => {
  const { config, table, visualProfileColors } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const isPending = status === PaymentStatusType.Pending;
  const pendingLabel = t('Pending');
  const orderNo = orderId || (isPending ? pendingLabel : '');

  const renderContent = () => {
    if (status === PaymentStatusType.Success || isPending) {
      return (
        <Wrapper>
          <TransactionStatus
            status="Success"
            title={t('Basket.Success')}
            description={t(
              isPending ? 'Basket.PaymentPending' : 'Basket.PaymentApproved'
            )}
            averageWaitTimeSeconds={averageWaitingTimeInSeconds}
            orderNumber={orderNo}
            orderNumberLabel={`${t('Order')}:`}
            averageWaitingTimeLabel={`${t('EstimatedWaitingTime')}:`}
            buttonText={t('GoBack')}
            onButtonClicked={() => onGoToStart()}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <TransactionStatus
          status="Failed"
          title={t('Basket.TransactionFailed')}
          buttonText={t('Basket.BackToOverview')}
          onButtonClicked={() => onGoToBasketOverview()}
        />
      </Wrapper>
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Payment')}
      subTitle={`${
        config?.orderReferenceCaption
          ? `${config.orderReferenceCaption}:`
          : 'Table'
      } ${table.name}`}
    >
      {renderContent()}
    </BasketModalContent>
  );
};

export default PaymentStatus;
